<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <span class="textSection1 textBlue" v-html="$t('textSection1')"></span>
        <span class="textSection1 textGrey" v-html="$t('textSection2')"></span>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 6" v-bind:class="mobile ? 'textSectionMobile' : 'textSection'">
        <br v-if="mobile">
        <p class="textSection1 textBlue" v-html="$t('textSection3')"></p>
        <v-btn v-bind:class="mobile ? 'contactUsBtnMobile' : 'contactUsBtn'"
               rounded
               height="65px"
               dark
               v-html="$t('contactUs')"
               @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
               color="#57939E">
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'HealthCoursesComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "The health courses in my establishment",
    "textSection1": "<strong> The Health Path: « I move in my establishment » is an adapted health path created with the help of visual arrows which will allow residents to meet 6 digital tablets positioned in different places of the establishment . </strong> <br>",
    "textSection2": "Each tablet offers a different exercise. The goal is to mobilize all the muscles and joints during the course. <br> <br> The health trail will allow the most independent residents to remain active throughout the establishment, but it may also allow other residents accompanied by a member of staff or a member of their family to practice a double activity: <br> walking as well as the various exercises offered on the tablets.",
    "textSection3": "In order to try to stick to the recommendations, each exercise lasts 3 to 5 minutes and the total travel time between all the tablets is estimated at 10 minutes. The use of tablets will allow us to change the exercises offered over time.",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "Les parcours santé dans mon établissement",
    "textSection1": "<strong>Le Parcours santé : « Je bouge dans mon établissement » est un parcours de santé adapté créé à l’aide de repères visuels fléchés qui permettront aux résidents d’aller à la rencontre de 6 tablettes numériques positionnées à différents endroits de l’établissement. </strong> <br>",
    "textSection2": "Chaque tablette propose un exercice différent. Le but étant de mobiliser l’ensemble des muscles et des articulations durant le parcours. <br><br> Le parcours santé va permettre aux résidents les plus autonomes de rester actifs à travers tout l’établissement, mais il pourra également permettre aux autres résidents accompagnés d’un membre du personnel ou d’un membre de sa famille de pratiquer une double activité : <br> la marche ainsi que les différents exercices proposés sur les tablettes.",
    "textSection3": "Afin d’essayer de respecter les recommandations, chaque exercice dure de 3 à 5 minutes et le temps de déplacement total entre toutes les tablettes est estimé à 10 minutes. L’utilisation de tablettes nous permettra de changer les exercices proposés au fil du temps.",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>

.textBlue {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

@media screen and (max-width: 1500px) {
  .section {
    font-size: 14px;
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 1500px) {
  .section {
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px;
  }
}

.sectionMobile {
  font-size: 14px;
  padding-bottom: 50px;
}

.textSection {
  padding-right: 150px;
}

.textSection1 {
  text-align: left;
}

.textSectionMobile {
  text-align: center;
}

.contactUsBtn {
  margin-top: 50px;
  margin-left: 50px;
  font-size: 18px;
  text-transform: none;
  width: 500px;
}

.contactUsBtnMobile {
  margin-top: 20px;
  font-size: 16px;
  text-transform: none;
  width: 300px;
}

</style>
